<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>元数据管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/coalSystemManage-systemManage' }">
        {{crumbA}}
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        {{crumbB}}
      </el-breadcrumb-item>
    </el-breadcrumb>


    <!--    <span style="font-size: 12px">项目系统概述：</span>-->
    <!--    <div class="coalblock" v-for="item in options" :key="item.title">-->
    <!--      <span style="font-size: 12px; color: #9196a1">{{ item.title }}</span>-->
    <!--      <br />-->
    <!--      <span style="font-size: 24px">{{ item.num }}</span>-->
    <!--    </div>-->
    <!--    <coal-over-view></coal-over-view>-->
    <over-view-of-system :table-data="tableData" :total="total" v-if="overViewFlag"></over-view-of-system>

    <el-form :inline="true">
      <el-form-item>
        <el-button v-if="isAuth('subSystem:add') && setting.backups" type="primary" @click="addSubSystem">增加子系统</el-button>
        <el-button v-if="isAuth('subSystem:delete') && setting.batchremove" type="danger" @click="deleteSubSystem()">批量删除子系统</el-button
        >
      </el-form-item>
      <span style="font-size: 10px">搜索：</span>
      <el-cascader
          :options="parentOptions"
          separator=" | "
          style="width: 400px"
          :props="props"
          placeholder="请选择系统"
          :clearable="true"
          ref="cascade"
          filterable
          @change="handleSearch()"
      ></el-cascader>
    </el-form>

    <br>

    <el-table
        :data="tableData"
        style="min-width: 100%"
        border
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
    >
      <el-table-column
          type="selection"
          header-align="center"
          align="center"
          min-width="5%"
      >
      </el-table-column>
      <el-table-column
          label="序号"
          type="index"
          min-width="5%"
          align="center">
        <template slot-scope="scope">
          <span>{{(page - 1) * size + scope.$index + 1}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="id"
          label="子系统id"
          header-align="center"
          align="center"
          min-width="5%"
          v-if="false"
      >
      </el-table-column>
      <el-table-column
          prop="name"
          label="子系统名称"
          header-align="center"
          align="center"
          min-width="10%"
      >
      </el-table-column>
      <el-table-column
          prop="equipmentCount"
          label="设备个数"
          header-align="center"
          align="center"
          min-width="10%"
      >
      </el-table-column>
      <el-table-column
          prop="createDate"
          label="创建时间"
          header-align="center"
          align="center"
          min-width="10%"
      >
      </el-table-column>
      <el-table-column
          label="操作"
          header-align="center"
          align="center"
          min-width="10%"
      >
        <template slot-scope="scope">
          <!--          <el-button-->
          <!--              type="text"-->
          <!--              size="small"-->
          <!--              @click="handleCheck(scope.row)"-->
          <!--              :disabled="scope.row.subSystemCount == 0?true:false"-->
          <!--          >查看-->
          <!--          </el-button>-->
          <el-button
              type="text"
              size="small"
              @click="handleCheck(scope.row)"
          >查看
          </el-button>
          <el-button
              v-if="isAuth('subSystem:update')"
              type="text"
              size="small"
              @click="updateHandle(scope.row)"
          >修改</el-button>
          <el-button
              v-if="isAuth('subSystem:delete')"
              type="text"
              size="small"
              style="color: red"
              @click="deleteSubSystem(scope.row.id)"
          >删除</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
          prop="remark"
          label="备注"
          header-align="center"
          align="center"
          min-width="20%"
      >
      </el-table-column>
    </el-table>

    <add-sub-system
        v-if="addSubSystemDialog"
        ref="addSubSystem"
        @update="update"
    ></add-sub-system>

    <update-sub-system
        v-if="updateVisible"
        ref="updateSubSystem"
        @refreshDataList="initDataWithGlobalVar"
    ></update-sub-system>

    <!-- 分页区域 -->
    <div style="display: flex; justify-content: flex-end">
      <el-pagination
          background
          @current-change="currentChange"
          @size-change="sizeChange"
          :current-page="page"
          :page-size="size"
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import addSystem from "./addSystem";
import addSubSystem from "./addSubSystem";
import coalOverView from "./coalOverView";
import updateSubSystem from "./updateSubSystem";
import OverViewOfSystem from "./overViewOfSystem";

export default {
  name: "subSystemManage",
  data() {
    let that = this;
    return {
      name:'项目子系统',
      dataListLoading: false,
      dataListSelections: [],
      addSubSystemDialog: false,
      // options: [
      //   {
      //     title: "总数据量",
      //     num: "1.2T",
      //   },
      //   {
      //     title: "系统个数",
      //     num: "8个",
      //   },
      //   {
      //     title: "子系统系统个数",
      //     num: "12个",
      //   },
      //   {
      //     title: "设备个数",
      //     num: "125",
      //   },
      // ],
      parentOptions: [],
      tableData: [],
      page: 1, //当前第几页
      size: 10, //当前每页页数
      total: 0, //全部数据量

      currOperator: '',
      currOperatorName: '',
      setting: {
        backups: true,
        batchremove: true,
      },
      updateVisible: false,

      overViewFlag: false,

      crumbA: '',
      crumbB: '',

      sizeFromSet: 10,

      props: {
        //级联选择器懒加载
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          console.log(level, node);
          if (level == 0) {
            that.getAllCoal((list1) => {
              let arr = list1.map((e) => ({ value: e.id, label: e.coal }));
              resolve(arr); // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            });
          }
          if (level == 1) {
            let { value } = node;
            console.log(value);
            that.getSystem(value, (list2) => {
              if (list2.length === 0) {
                let arr = [{
                  value: "无数据",
                  label: "无数据",
                  disabled: true,
                  leaf: true,
                }]
                resolve(arr);
              } else {
                let arr = list2.map((e) => ({
                  value: e.id,
                  label: e.name,
                  leaf: true,
                }));
                resolve(arr);
              }
            });
          }
        },
      },
    };
  },
  created(){


  },
  mounted() {
    //获取session中的user的id和name
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.currOperatorName = JSON.parse(
        window.sessionStorage.getItem("user")
    ).username;

    //根据名字查询系统设置中的结果
    this.getRequest(
        "/systemSetting/getSettingInfoByName/?name=" + this.name
    ).then((resp) => {
      if (resp.data) {
        console.log(this.name);
        console.log(resp.data);
        this.setting.backups = resp.data.backups;
        this.setting.batchremove = resp.data.batchremove;
        this.sizeFromSet = resp.data.numberperpage;
        this.size = this.sizeFromSet
      }
    });
    if(this.global.system_id === 0) {
      this.initData_()
    } else {
      this.initDataWithGlobalVar()
    }
    // if (this.$route.query.hasOwnProperty("systemId")) {
    //   this.handleJump();
    // } else {
    //   this.initData();
    // }
  },
  components: {
    OverViewOfSystem,
    addSubSystem,
    coalOverView,
    updateSubSystem
  },
  methods: {
    currentChange(currentPage) {
      this.page = currentPage;
      this.initDataWithGlobalVar()
      // if(this.$route.query.hasOwnProperty("systemId")) {
      //   if (this.$refs["cascade"].checkedValue === null) {
      //     this.handleJump()
      //   } else {
      //     this.handleChange()
      //   }
      // } else {
      //   this.handleChange();
      // }
    },
    sizeChange(currentSize) {
      this.size = currentSize;
      this.initDataWithGlobalVar()
      // if(this.$route.query.hasOwnProperty("systemId")) {
      //   if (this.$refs["cascade"].checkedValue === null) {
      //     this.handleJump()
      //   } else {
      //     this.handleChange()
      //   }
      // } else {
      //   this.handleChange();
      // }
    },
    resetSizePage() {
      this.size = this.sizeFromSet
      this.page = 1
    },

    initData_() {
      if (this.global.coal_id === 0) {
        this.getRequest("/dataList/getAllCoal/").then((resp) => {
          if (resp.data) {
            // 处理全局变量
            this.$changeGlobalVar(this.global.MODE_COAL, resp.data[0].id, resp.data[0].coal)
            // 处理面包屑
            this.crumbA = this.global.coal_name
          }
        }).then(() => {
          this.getRequest(
              "/coalManage/getSystemInfoByCoalId/?coalId=" + this.global.coal_id
          ).then((resp) => {
            if (resp.data) {
              // 处理全局变量
              this.$changeGlobalVar(this.global.MODE_SYSTEM, resp.data[0].id, resp.data[0].name)
              // 处理面包屑
              this.crumbB = this.global.system_name
            }
          })
        }).then(() => {
          this.initDataWithGlobalVar()
        });
      } else {
        this.getRequest(
            "/coalManage/getSystemInfoByCoalId/?coalId=" + this.global.coal_id
        ).then((resp) => {
          if (resp.data) {
            // 处理全局变量
            this.$changeGlobalVar(this.global.MODE_SYSTEM, resp.data[0].id, resp.data[0].name)
            // 处理面包屑
            this.crumbA = this.global.coal_name
          }
        }).then(() => {
          this.initDataWithGlobalVar()
        });
      }
    },
    initDataWithGlobalVar() {
      this.dataListLoading = true
      // 处理面包屑
      this.crumbA = this.global.coal_name
      this.crumbB = this.global.system_name
      this.getRequest(
          "/subSystemManage/getSubSystemInfoBySystemId?systemId=" +
          this.global.system_id + "&page=" +
          this.page +
          "&size=" +
          this.size
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
          this.overViewFlag = true;
        }
        this.dataListLoading = false
      });
    },
    handleSearch() {
      this.resetSizePage()
      if (this.$refs["cascade"].checkedValue === null ||this.$refs["cascade"].checkedValue.length == 0) {
        this.initDataWithGlobalVar()
      } else {
        //处理面包屑
        this.crumbA = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[0].label
        this.crumbB = this.$refs["cascade"].getCheckedNodes()[0].pathNodes[1].label

        // 处理全局变量
        this.$changeGlobalVar(this.global.MODE_COAL, this.$refs["cascade"].getCheckedNodes()[0].pathNodes[0].value, this.crumbA)
        this.$changeGlobalVar(this.global.MODE_SYSTEM, this.$refs["cascade"].getCheckedNodes()[0].pathNodes[1].value, this.crumbB)

        this.initDataWithGlobalVar()
      }
    },
    handleCheck(row) {
      this.global.subSystem_id = row.id
      this.global.subSystem_name = row.name
      this.$router.push({
        name: 'coalSystemManage-deviceManage',
      })
      // this.$router.push({
      //   name: 'coalSystemManage-deviceManage',
      //   query: {
      //     subSystemId: row.id
      //   }
      // });
    },

    initData() {
      this.dataListLoading = true
      this.getRequest(
          "/subSystemManage/getSubSystemInfoByPage/?page=" +
          this.page +
          "&size=" +
          this.size + "&userId=" + this.currOperator
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
          this.overViewFlag = true;
        }
        this.dataListLoading = false
      });
    },
    async getAllCoal(callback) {
      this.getRequest("/dataList/getAllCoal/").then((resp) => {
        if (resp.data) {
          callback(resp.data);
          console.log(resp.data);
        }
      });
    },
    handleChange() {
      if (this.$refs["cascade"].checkedValue === null || this.$refs["cascade"].checkedValue.length == 0) {
        this.dataListLoading = true
        this.getRequest(
            "/subSystemManage/getSubSystemInfoByPage/?page=" +
            this.page +
            "&size=" +
            this.size + "&userId=" + this.currOperator
        ).then((resp) => {
          if (resp.data) {
            this.tableData = resp.data.data;
            this.total = resp.data.total;
            this.overViewFlag = true;
          }
          this.dataListLoading = false
        });
      } else {
        this.dataListLoading = true
        this.getRequest(
            "/subSystemManage/getSubSystemInfoBySystemId?systemId=" +
            this.$refs["cascade"].getCheckedNodes()[0].value + "&page=" +
            this.page +
            "&size=" +
            this.size
        ).then((resp) => {
          if (resp.data) {
            this.tableData = resp.data.data;
            this.total = resp.data.total;
            this.overViewFlag = true;
          }
          this.dataListLoading = false
        });
      }
    },
    handleJump() {
      this.dataListLoading = true
      this.getRequest(
          "/subSystemManage/getSubSystemInfoBySystemId?systemId=" +
          this.$route.query.systemId + "&page=" +
          this.page +
          "&size=" +
          this.size
      ).then((resp) => {
        if (resp.data) {
          this.tableData = resp.data.data;
          this.total = resp.data.total;
          this.overViewFlag = true;
        }
        this.dataListLoading = false
      });
    },
    async getSystem(coalId, callback) {
      this.getRequest(
          "/coalManage/getSystemInfoByCoalId?coalId=" + coalId
      ).then((resp) => {
        if (resp.data) {
          console.log("check", resp)
          callback(resp.data);
        }
      });
    },
    addSubSystem() {
      this.addSubSystemDialog = true;
      this.$nextTick(() => {
        this.$refs.addSubSystem.init();
      });
    },
    update() {
      this.initDataWithGlobalVar();
    },
    // 复选框
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 删除子系统
    deleteSubSystem(id) {
      let ids = "?";
      this.$confirm(
          `确定对子系统进行[${id ? "删除" : "批量删除"}]操作?`,
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      )
          .then(() => {
            if (id) {
              this.deleteEquipment(id);
              ids = "?ids=" + id;
            } else {
              // 先删除子系统下的设备
              this.deleteEquipment(id);
              this.dataListSelections.forEach((item) => {
                ids += "ids=" + item.id + "&";
              });
            }
            this.deleteRequest("/subSystemManage/deleteSubSystem/" + ids).then(
                (resp) => {
                  if (resp.data) {
                    //this.handleChange();
                    this.initDataWithGlobalVar();
                  }
                }
            );
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
    },
    // 删除设备
    async deleteEquipment(id) {
      let ids = "?";
      let equipment = await this.getRequest(
          "/equipmentManage/getEquipmentInfoBySubSystemId?subSystemId=" + id
      );
      if (equipment.length) {
        equipment.forEach((item) => {
          // 先删除所有的附属设备
          this.deleteSubEquipment(item.id);
          ids += "ids=" + item.id + "&";
        });
        await this.deleteRequest("/equipmentManage/deleteEquipment/" + ids);
      }
    },
    // 删除附属设备
    async deleteSubEquipment(id) {
      let ids = "?";
      let subEquipment = await this.getRequest(
          "/subEquipmentManage/getSubEquipmentInfoByEquipmentId?equipmentId=" + id
      );
      if (subEquipment.length) {
        subEquipment.forEach((item) => {
          // 先删除附属设备的所有数据项
          this.deleteMeasurePoint(item.id);
          ids += "ids=" + item.id + "&";
        });
        await this.deleteRequest(
            "/subEquipmentManage/deleteSubEquipment/" + ids
        );
      }
    },
    // 删除附属设备下的所有数据项
    async deleteMeasurePoint(id) {
      let ids = "?";
      let measurePoint = await this.getRequest(
          "/measurePointManage/getMeasurePointInfoBySubEquipmentId?subEquipmentId=" +
          id
      );
      if (measurePoint.length) {
        measurePoint.forEach((item) => {
          ids += "ids=" + item.id + "&";
        });
        await this.deleteRequest(
            "/measurePointManage/deleteMeasurePoint/" + ids
        );
      }
    },
    updateHandle (row) {
      this.updateVisible = true
      this.$nextTick(() => {
        this.$refs.updateSubSystem.init(row)
      })
    },
  },
};
</script>

<style scoped>
.coalblock {
  /* width: 115px;
  height: 77px;
  border: solid 1px #dcdfe6;
  padding-top: 20px;
  margin-left: 25px;
  text-align: center;
  display: inline-block; */
  text-align: center;
  display: inline-block;
  padding: 3px 5px;
}
</style>
